import Modal from "components/Modal"
import Spinner from "components/Spinner"

export default function Component({ loading }) {
    return (
        <Modal visibleModal={loading} setVisibleModal={null}>
            <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "var(--color-yellow)" }}>
                <Spinner />
            </div>
        </Modal>
    )
}