import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Topbar, TopbarMenuDesktop, TopbarMenuMobile, Logout, TopbarActions } from './styled'
import { FaBars } from "react-icons/fa"
import { Context } from 'contexts/context'
import { logout } from 'services/auth'

import api from "../../services/api/api";


const Component = (props) => {

  const history = useHistory()
  const { toggle, setToggle } = useContext(Context)

  const handleLogout= async () => {
    try {
      await api.delete("/users/logout")
    } catch(error) {

    } finally {
      logout(history)
    }
  }

  return (
    <Topbar>
      <TopbarMenuDesktop onClick={() => setToggle(!toggle)}>
        {!props.error && (
          <span style={{ marginLeft: toggle ? "200px" : "100px" }}><FaBars /></span>
        )}
      </TopbarMenuDesktop>
      <TopbarMenuMobile
        onClick={() => setToggle(!toggle)}
        className={toggle ? 'menu-open' : 'menu-closed'}
      >
        {!props.error && (
          <span><FaBars /></span>
        )}
      </TopbarMenuMobile>
      <TopbarActions>
        <div>
          <Logout onClick={() => handleLogout(history)}>Sair</Logout>
        </div>
      </TopbarActions>
    </Topbar>
  )
}

export default Component
