import { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload';
import { models } from 'powerbi-client'

import Swal from "sweetalert2"

import { Context } from 'contexts/context'

import { FloatingButton, Item } from 'components/FloatingButton'
import Modal from 'components/Modal'
import CardBody from 'components/CardBody'
import Content from 'components/Content'
import Card from 'components/Card'
import Spinner from 'components/Spinner'
import BtnBox from 'components/Button/BtnBox'
import BtnOrange from 'components/Button/BtnOrange'
import Dropdown from 'components/Dropdown'
import BtnWhite from 'components/Button/BtnWhite'

import { handleReport } from 'services/api/reports'

import { handleCreateUserActionReport, handleUpdateUserActionReport } from 'services/api/usersActionsReports'
import { handlePowerBiExportReport, handlePowerBiReportAad, handlePowerBiReportEmbed } from 'services/api/powerbi'

import { getUser } from 'services/auth'
import { handleUserShow } from 'services/api/users'
import { PowerBIEmbed } from 'powerbi-client-react'

import { downloadFile } from "utils/index"

import './style.css'

const Page = () => {
  const [data, setData] = useState({})
  const [report, setReport] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const [userActionReportId, setUserActionReportId] = useState()

  console.log(data)

  const [isDownloading, setIsDownloading] = useState(false)
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  const params = useParams()
  const history = useHistory()

  const { user } = useContext(Context)
  
  const { group_id, report_id, dataset_id } = params

  const getData = async () => {
    if (user.roles === 'admin') {
      const reportData = await handlePowerBiReportAad({ report_id })

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.accessToken,
        type: models.TokenType.Aad
      })

      return reportData.accessToken;
    }

    const roles = await handleReport(report_id, user.id)

    if (roles.roles) {
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        roles: roles.roles
      })

      handleUserAction(roles.report_card_id)

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        page_navigation: roles.page_navigation,
        enable_filters: roles.enable_filters,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration
      })

      return reportData.embedToken;
    }

    const reportData = await handlePowerBiReportAad({ report_id })

    handleUserAction(roles.report_card_id)

    setData({
      reportId: reportData.reportId,
      embedUrl: reportData.embedUrl,
      token: reportData.accessToken,
      page_navigation: roles.page_navigation,
      enable_filters: roles.enable_filters,
      type: models.TokenType.Aad
    })

    return reportData.embedToken;
  }

  useEffect(() => {
    if (user) getData()
  }, [user])

  const handleUserAction = async (report_card_id) => {
    const userActionReportResponse =
      await handleCreateUserActionReport({ report_id: report_card_id })

    setUserActionReportId(userActionReportResponse)
  }

  useBeforeunload(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }
  })

  const unblock = history.block(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }

    unblock()
  })

  window.screen.orientation.onchange = event => {
    if (event.target.type === "landscape-primary") {
      report.fullscreen()

      return
    }

    if (document.fullscreenElement) report.exitFullscreen()
  }

  const handleExport = async ({ format, extension }) => {
    setIsDownloading(true)

    try {
      const data = await handlePowerBiExportReport({
        format,
        report_id
      })

      downloadFile(data, `Relatório.${extension}`)

      Swal.fire(
        'Sucesso',
        'Exportação efetuada com sucesso!',
        'success'
    )
    } catch(error) {
      Swal.fire(
        'Erro',
        'Houve um problema ao efetuar a exportação!',
        'error'
    )
    } finally {
      setIsDownloading(false)
    }
  }

  const embedConfig = {
    type: 'report',
    id: data.reportId,
    embedUrl: data.embedUrl,
    eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
    accessToken: data.token,
    tokenType: data.type || models.TokenType.Aad,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
         displayOption: models.DisplayOption.FitToPage
      },
      panes: {
        filters: {
          visible: data.enable_filters ? data.enable_filters : false
        },
        pageNavigation: {
          visible: data.page_navigation ? data.page_navigation : false
        }
      }
    }
  }

  return (
    <div id='ReportPageContainer'>
      {isLoaded && (user.view_report_options === 1 || user.roles === "admin") && (
        <div id='ReportContainer'>
          <div id='ReportContainerOptions'>
            <BtnOrange
              onClick={() => report.fullscreen()}
              isLoading={false}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                padding: 0,
                backgroundColor: 'var(--color-green)'
              }}
            >
              <img
                src="/svgs/fullscreen.svg"
                className='imageButtonReport'
                alt={"icon"}
              />
            </BtnOrange>

            <BtnOrange
              onClick={() => report.print()}
              isLoading={false}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                padding: 0,
                backgroundColor: 'var(--color-green)'
              }}
            >
              <img
                src="/svgs/print.svg"
                className='imageButtonReport'
                alt={"icon"}
              />
            </BtnOrange>

            <BtnOrange
              onClick={() => report.refresh()}
              isLoading={false}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                padding: 0,
                backgroundColor: 'var(--color-green)'
              }}
            >
              <img
                src="/svgs/autorenew.svg"
                className='imageButtonReport'
                alt={"icon"}
              />
            </BtnOrange>
          </div>

          {/* <Dropdown
            isLoading={isDownloading}
            text="Exportar"
            style={{
              width: "20px",
              height: "20px"
            }}
            btSize='10pt'
            items={[
                {
                    text: "PNG",
                    onClick: () => handleExport({ format: "PNG", extension: "zip" })
                },
                {
                    text: "PDF",
                    onClick: () => handleExport({ format: "PDF", extension: "pdf" })
                }
            ]}
          /> */}
        </div>
      )}

      <PowerBIEmbed
        eventHandlers = {
          new Map([
              ['loaded', () => console.log("Report loaded")],
              ['rendered', () => setIsLoaded(true)],
              ['error', event => console.log(event.detail)]
          ])
        }
        getEmbeddedComponent={(embedObject) => setReport(embedObject)}
        embedConfig={embedConfig}
        cssClassName={"report"}
      />
    </div>
  )
}

export default Page